import { FC } from "react";
import { useAppDispatch, useAppSelector } from "hooks";
import { AutoComplete, TextField } from "@xcira/components";

import { SortLabel, selectOrderBy, setOrderBy, sortLabels } from "slices";

interface OrderByProps {
  className?: string;
}

export const OrderBy: FC<OrderByProps> = ({ className }) => {
  const dispatch = useAppDispatch();
  const selectedOrderBy = useAppSelector(selectOrderBy);

  const handleSort = (label: SortLabel) => {
    dispatch(setOrderBy({ label }));
  };

  return (
    <AutoComplete
      data-testid="orderByFilter"
      disablePortal
      className={className}
      options={sortLabels}
      renderInput={(params) => (
        <TextField
          {...params}
          data-testid="orderByFilterInput"
          label="Sort By"
          variant="outlined"
        />
      )}
      renderOption={(props, option) => (
        <li {...props} key={option} data-testid={`sortOption${option.replaceAll(" ", "-")}`}>
          {option}
        </li>
      )}
      onChange={(event, value) => {
        if (value !== null) handleSort(value as SortLabel);
      }}
      value={selectedOrderBy?.label}
      openOnFocus
    />
  );
};
