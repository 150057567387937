import { memo, useMemo, FC } from "react";
import { Icon } from "@xcira/components";
import type { IconProps } from "@xcira/components";
import { AvStreamStatus } from "graphql/generated";
import { useSelectAvPublisherByPort } from "hooks/useAvPublishers";
import { isPaused, getStatus } from "utils/AvStreamUtil";
import { Maybe } from "../../../graphql/generated";

type Color = IconProps["color"];

type Size = IconProps["fontSize"];

const iconColors: Record<AvStreamStatus | "none", Color> = {
  [AvStreamStatus.Stopped]: "error",
  [AvStreamStatus.Open]: "secondary",
  [AvStreamStatus.Reconnecting]: "secondary",
  [AvStreamStatus.Streaming]: "success",
  [AvStreamStatus.Paused]: "warning",
  none: "disabled",
};

const getColor = (status?: AvStreamStatus, statusInfo?: Maybe<string>): Color => {
  if (isPaused(status, statusInfo)) return "warning";

  return iconColors[status ?? "none"];
};

interface StreamStatusIconProps {
  avPort?: Maybe<number>;
  size?: Size;
}

const StreamStatusIcon: FC<StreamStatusIconProps> = memo(({ avPort, size = "large" }) => {
  const avPublisherStatus = useSelectAvPublisherByPort(
    avPort,
    (avPublisher) => avPublisher?.status
  );
  const avPublisherStatusInfo = useSelectAvPublisherByPort(
    avPort,
    (avPublisher) => avPublisher?.statusInfo
  );

  const color = useMemo(() => getColor(avPublisherStatus, avPublisherStatusInfo), [
    avPublisherStatus,
    avPublisherStatusInfo,
  ]);

  return (
    <Icon
      icon="brightness_1"
      fontSize={size}
      color={color}
      title={getStatus(avPublisherStatus, avPublisherStatusInfo)}
      data-testid={`streamStatusIcon${avPort}`}
    />
  );
});

export { StreamStatusIcon };
